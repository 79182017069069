import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider, Box , CSSReset} from "@chakra-ui/react";
import Landing from "./Landing"

import customTheme from "../utils/theme";

const IndexPage = () => {
  return (
    <main>
      <ChakraProvider theme={customTheme}>
        <Landing />
      </ChakraProvider>
    </main>
  )
}

export default IndexPage
